import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class ProductCatalogRootService {


    paginate(index = null) {
        let url = API_URL + 'user/ecommerce/root-catalog';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    get(id) {
        let url = API_URL + 'user/ecommerce/root-catalog/'+id;
        return apiService.get(url);
    }
    create(data) {
        let url = API_URL + 'user/ecommerce/root-catalog';
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = API_URL + 'user/ecommerce/root-catalog/' + id
        return apiService.put(url, data);
    }

    delete(id) {
        let url = API_URL + 'user/ecommerce/root-catalog/' + id
        return apiService.delete(url);
    }
    show(id) {
        let url = API_URL + 'user/ecommerce/root-catalog/' + id
        return apiService.get(url);
    }

    getAll(){
        let url = API_URL + 'user/ecommerce/root-catalog';
        return apiService.get(url);
    }
}
