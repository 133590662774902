import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class ProductService {

      syncAttribute(id,data){
            let url = API_URL + `user/ecommerce/product/${id}/attribute/save`;
            return apiService.post(url, data);
      }

      create(id, data){
            let url = API_URL + `user/ecommerce/product/${id}/attribute`;
            return apiService.post(url, data);
      }
}
