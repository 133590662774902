<template>
  <v-col cols="12" md="12">
    <KTPortlet v-bind:title="'Product Warranty'">
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <v-row>
          <v-col cols="6">
            <v-select :items="warrentyType" v-model="product.warranty_type" outlined dense label="Warranty Type"></v-select>
          </v-col>
          <v-col cols="6">
            <v-select :items="warrentyPeriod" v-model="product.warranty_period" outlined dense label="Warranty Period"></v-select>
          </v-col>
          <v-col cols="12">
            <span>Policy</span>
            <ckeditor v-model="product.warranty_policy"></ckeditor>
          </v-col>
<!--          <v-col cols="12">-->
<!--            <v-btn-->
<!--             :loading="isLoading"-->
<!--              color="white&#45;&#45;text v-btn theme&#45;&#45;light elevation-2 v-size&#45;&#45;large primary"-->
<!--              class="btn-save-popup pull-right"-->
<!--              text-->
<!--              @click.prevent="updateGeneral()"-->
<!--            >Save</v-btn>-->
<!--          </v-col>-->
        </v-row>
      </template>
    </KTPortlet>
  </v-col>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import CKEditor from "ckeditor4-vue";
export default {
  name: "store-info",
  props: ["product","isLoading"],
  data: () => ({
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    },
    warrentyType: [
      "International Manufacturer Warranty",
      "Non-local Warranty",
      "No Warranty",
      "Local Seller Warranty",
      "Internatinal Seller Warranty",
      "International Warranty"
    ],
    warrentyPeriod: [
      "1 Months",
      "3 Months",
      "6 Months",
      "1 Year",
      "2 Year",
      "5 Year",
      "Life Time"
    ]
  }),
  components: {
    KTPortlet,
    ckeditor: CKEditor.component
  },
  methods: {
    updateGeneral() {
      this.$emit("update");
    }
  }
};
</script>
