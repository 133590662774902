import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class EcommerceBrandManagerService {

      #api;
      constructor() {
            this.#api = API_URL + 'user/ecommerce/brand';
      }

      paginate() {
            let url = `${this.#api}`;
            return apiService.get(url)
      }

      create(data) {
            let url = `${this.#api}`;
            return apiService.post(url, data)
      }

      show(id) {
            let url = `${this.#api}/${id}`;
            return apiService.get(url)
      }

      update(id, data) {
            let url = `${this.#api}/${id}`;
            return apiService.post(url, data)
      }

      delete(id) {
            let url = `${this.#api}/${id}`;
            return apiService.delete(url)
      }

      byUser(){
            let url = `${this.#api}`;
            return apiService.get(url)
      }

      forProduct(productId){
            let url = `${API_URL}user/ecommerce/product/${productId}/brand`;
            return apiService.get(url)
      }
}
