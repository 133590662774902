<template>
  <v-col cols="12" md="12">
    <KTPortlet v-bind:title="'Packaging & Delivery'">
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <h3>{{product.weight}}</h3>
        <v-row>
          <v-col cols="4">
            <v-text-field
              outlined
              v-model="deliveryPackage.length"
              densed
              suffix="cm"
              label="Length"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="deliveryPackage.width" densed suffix="cm" label="Width"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              v-model="deliveryPackage.height"
              densed
              suffix="cm"
              label="Height"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols=12><h4>Dangerous Good Declaration</h4></v-col>
              <v-col>
                <v-checkbox
                  v-model="deliveryPackage.dangerous_goods"
                  label="Battery"
                  value="Battery"
                  type="number"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="deliveryPackage.dangerous_goods"
                  label="Flammable"
                  value="Flammable"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox v-model="deliveryPackage.dangerous_goods" label="Liquid" value="Liquid"></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox v-model="deliveryPackage.dangerous_goods" label="None" value="None"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-switch label="Fragile" v-model="deliveryPackage.is_fragile"></v-switch>
              </v-col>
              <v-col cols="12">
                <v-btn
                  :loading="isLoading"
                  color="white--text v-btn theme--light elevation-2 v-size--large primary"
                  class="btn-save-popup pull-right"
                  text
                  @click.prevent="saveDeliveryPackage()"
                >Save</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </KTPortlet>
  </v-col>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import CKEditor from "ckeditor4-vue";
import ProductService from "@/services/store/ProductService";
import ProductDeliveryPackageService from "@/services/store/ProductDeliveryPackageService";
const Product = new ProductService();
const productDeliveryPackage = new ProductDeliveryPackageService();
export default {
  name: "store-info",
  props: ["product","isLoading"],
  data: () => ({
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    },
    deliveryPackage: {
      id: null,
      weight: null,
      length: null,
      width: null,
      dangerous_goods: [],
      is_fragile: false
    }
  }),
  components: {
    KTPortlet,
    ckeditor: CKEditor.component
  },
  methods: {
    saveDeliveryPackage() {
      if (this.deliveryPackage.id != null && this.deliveryPackage.id != undefined) {
        productDeliveryPackage
          .update(this.productid, this.deliveryPackage.id, this.deliveryPackage)
          .then(res => {
            this.$snotify.success("Delivery updated");
            this.deliveryPackage = res.delivery
          })
          .catch(err => {});
      } else {
        productDeliveryPackage
          .create(this.productid, this.deliveryPackage)
          .then(res => {
            this.$snotify.success("Delivery created");
            this.deliveryPackage = res.delivery
          })
          .catch(err => {});
      }
    },
    getDeliveryPackage() {
      productDeliveryPackage
        .paginate(this.productid)
        .then(res => {
          this.deliveryPackage = res.data;
        })
        .catch(err => {});
    },
    updateGeneral() {
      this.$emit("update");
    }
  },
  computed: {
    productid() {
      return this.$route.params.productid;
    }
  },
  mounted() {
    this.getDeliveryPackage();
  }
};
</script>
