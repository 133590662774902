<template>
    <KTPortlet v-bind:title="'Catalog'">
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <div class="kt-notification kt-notification--fit">
          <treeselect
            :multiple="true"
            :options="options"
            :sort-value-by="sortValueBy"
            placeholder="Choose catalogs"
            v-model="product.catalog_ids"
            :value-consists-of="valueConsistsOf"
              :disable-branch-nodes="false"
          />
            <!-- <treeselect-value :value="product.catalog_ids" /> -->

          <!-- <treeselect
            :multiple="true"
            :options="options"
            :clearable="clearable"
            v-model="product.catalog_ids"
            :normalizer="normalizer"
            search-nested
          /> -->
        </div>
<!--        <v-col cols="12">-->
<!--          <v-btn-->
<!--            :loading="isLoading"-->
<!--            color="white&#45;&#45;text v-btn theme&#45;&#45;light elevation-2 v-size&#45;&#45;large primary"-->
<!--            class="btn-save-popup pull-right"-->
<!--            text-->
<!--            @click.prevent="updateCatalog()"-->
<!--          >Save</v-btn>-->
<!--        </v-col>-->
      </template>
    </KTPortlet>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ProductCatalogRootService from "@/services/store/ProductCatalogRootService";
const rootCatalogService = new ProductCatalogRootService();

export default {
  name: "store-catlogs",
  props: ["product", "isLoading"],
  data: () => ({
    value: null,
    sortValueBy: "ORDER_SELECTED",
      valueConsistsOf: 'ALL_WITH_INDETERMINATE',
    options: [],
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.children
      };
    }
  }),
  components: {
    KTPortlet,
    treeselect: Treeselect
  },
  mounted() {
    this.getCatalogs();
  },
  methods: {
    getCatalogs() {
      rootCatalogService
        .getAll()
        .then(response => {
          if (response.data.length > 0) {
            response.data.forEach(ele => {
              ele.catalogs.forEach(e => {
                this.options.push(e);
              });
            });
          }
        })
        .catch(error => {});
    },
    updateCatalog() {
      this.$emit("update");
    }
  }
};
</script>
