import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class ProductTextDetailService {


    paginate(productID,index = null) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/detail';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    get(id) {
        let url = API_URL + 'user/ecommerce/ecommerce/product/' + productID + '/detail';
        return apiService.get(url);
    }
    getAttribute(productID) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/detail';
        return apiService.get(url);
    }
    create(productID,data) {

        let url = API_URL + 'user/ecommerce/product/' + productID + '/detail';;
        return apiService.post(url, data);
    }

    update(productID,id, data) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/detail/'+id;
        return apiService.put(url, data);
    }

    delete(productID,id) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/detail/'+id;
        return apiService.delete(url);
    }
    show(id) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/detail';
        return apiService.get(url);
    }


}
