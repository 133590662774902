<template>
  <v-col cols="12" md="12">
    <KTPortlet
      v-bind:title="'Customer Inputs'"
      v-if="optionslist ? optionslist.length>0 :0"
    >
      <template v-slot:toolbar>
        <v-btn
          @click="showDialog"
          class="btn additional-btn"

        >
          <i class="flaticon-add-circular-button"></i> Add Modifier
        </v-btn>
      </template>
      <template v-slot:body>
        <v-app>
          <v-expansion-panels focusable>
            <v-expansion-panel v-for="(item,i) in optionslist" :key="i" class="faq-list">
              <v-expansion-panel-header class="font-weight-regular">
                {{ item.title}}
                <span class="text-right">
                  <!-- <i  v-if="item.is_active" class="flaticon2-check-mark kt-font-success"></i>
                  <i v-else class="flaticon2-exclamation kt-font-warning"></i>-->
                  <a href="javascript:void(0)" @click="editTexts(item)" class="ps-1">
                    <img src="@/assets/media/icons/edit-tools.png" class="edit_icon" />
                  </a>
                  <a href="#" @click="deleteOptions(item.id)" class="ps-1">
                    <img src="@/assets/media/icons/send-to-trash.png" class="delete_icon" />
                  </a>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="font-weight-regular">
                <v-row>
                  <v-col cols="6">{{ item.title }}  <span v-if="item.required"> Required</span></v-col>
                  <v-col cols="6">
                    <v-text-field v-if="item.input_type=='number'" :label="item.title" required></v-text-field>

                    <v-text-field v-if="item.input_type=='text'" :label="item.title" required></v-text-field>
                    <v-select
                      v-if="item.input_type=='select'"
                      :items="item.values"
                      :label="item.title"
                      required
                    ></v-select>
                    <!-- <v-radio-group v-if="item.input_type=='radio'" v-model="row" row>
                      <v-radio v-for="(val,i) in item.value" :key="i" :label="val" :value="val"></v-radio>
                    </v-radio-group>
                    <v-checkbox
                      v-if="item.input_type=='checkbox'"
                      v-for="(val,i) in item.value"
                      :key="i"
                      :label="val"
                      :value="val"
                    ></v-checkbox>
                    <v-textarea v-if="item.input_type=='textArea'" :label="item.title"></v-textarea>-->
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-app>
      </template>
    </KTPortlet>

    <div class="kt-portlet kt-iconbox" v-else>
      <div class="kt-portlet__body">
        <div class="kt-iconbox__body">

          <div class="kt-iconbox__desc">
            <h3 class="kt-iconbox__title">
              <a class="kt-link" href="#">Modifiers</a>
            </h3>
            <div class="kt-iconbox__content">
              <a href="#" @click="showDialog">Allow your shoppers to customize your item with modifications or custom text.  </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog scrollable v-model="descriptionText" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <span v-if="!edit" class="headline">Add field to accept</span>
          <span v-else class="headline">Update</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col cols="2">
                    <v-switch label="Required" v-model="newOptions.required" outlined dense required>required</v-switch>
                </v-col>
              <v-col cols="4">
                <v-text-field label="Name" v-model="newOptions.title" outlined dense required></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="items"
                  item-text="label"
                  item-value="value"
                  v-model="newOptions.input_type"
                  outlined
                  dense
                  @change=" changeType(`${newOptions.input_type}`)"
                  label="Standard"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="showValues">
              <v-col>
                <v-combobox
                  v-model="newOptions.values"
                  :items="options"
                  hide-selected
                  label="Add some Values"
                  multiple
                  persistent-hint
                  small-chips
                  outlined
                  dense
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No results matching "
                          <strong>{{ search }}</strong>". Press
                          <kbd>enter</kbd> to create a new one
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Close</v-btn>
          <v-btn
          :loading="isLoading"
            color="white--text v-btn theme--light elevation-2 v-size--large primary"
            class="btn-save-popup"
            text
            @click.prevent="saveOrUpdate"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { required } from "vuelidate/lib/validators";
import NotifyService from "@/common/notify.service";
import ProductService from "@/services/store/ProductService";
import ProductTextDetailTextService from "@/services/store/ProductTextDetailTextService";

const Product = new ProductService();
const productDetailText = new ProductTextDetailTextService();
export default {
  name: "Product-texts",
  // props: ["texts", "slug"],
  validations: {
    newtexts: {
      required,
      $each: {
        title: { required },
        description: { required },
        sort_position: { required }
      }
    }
  },
  data: () => ({
    isLoading:false,
    showValues: false,
    slectedOption: null,
    descriptionText: false,
    edit: false,
    optionslist: [
      {
        title: "color",
        value: ["red", "blue"],
        input_type: "select",
        piceType: null,
        price: null,
        required: 0
      }
    ],

    items: [
      { label: "Dropdown", value: "select" },
      // { label: "Radio", value: "radio" },
      // { label: "Checkbox", value: "checkbox" },
      { label: "Text Field", value: "text" },
      { label: "Number", value: "number" }

      // { label: "Text Area", value: "textArea" }
    ],
    options: [],
    model: ["Vuetify"],
    search: null,
    newOptions: {
      title: "",
      values: [],
      input_type: "text",
      position: 1,
      is_active: 1
    },

    newtexts: [
      {
        title: "asdasd",
        description: "asdas",
        sort_position: 1,
        is_active: 1
      }
    ]
  }),
  components: {
    KTPortlet
  },
  computed: {
    productid() {
      return this.$route.params.productid;
    }
  },
  methods: {
    showDialog() {
      this.descriptionText = true;
    },
    close() {
      this.descriptionText = false;
        this.resetOptions();
      // this.resetDescription();
      // this.$emit("initOrganisation");
    },
    // Add Faq Form
    addTexts() {
      this.newtexts.push({
        title: "",
        description: "",
        sort_position: 1,
        is_active: 1
      });
    },
    editTexts(item) {
      if (item.id != null && item.id != undefined && item.id != "") {
        this.changeType(item.input_type)
        this.descriptionText = true;
        this.edit = true;
        this.newOptions = item;
      }
    },
    addOptions() {
      this.optionslist.push({
        value: null,
        type: null,
        piceType: null,
        price: null,
        required: null
      });
    },
    removeOption(index) {
      this.optionslist.splice(index, 1);
    },

    // Remove Faq Form
    removeFaq(index) {
      this.newtexts.splice(index, 1);
    },
    changeType(optionValue) {
      if (optionValue == "text" || optionValue == "number") {
        this.showValues = false;
      } else {
        this.showValues = true;
      }
    },
    // Save Faq call api
    saveOrUpdate() {
      if (!this.edit) {
        this.save();
      } else {
        this.update();
      }
    },
    getTextForm() {
      productDetailText.paginate(this.productid).then(res => {
        this.optionslist = res.data;

      });
    },
    save() {
      this.isLoading=true;
      let data = this.newOptions;
      productDetailText.create(this.productid, data).then(res => {
        this.$snotify.success("Text Form added");
         this.isLoading=false;
        this.resetOptions();
        this.getTextForm();
        this.close();
      });
    },
    update() {
      this.isLoading=true;
      let data = this.newOptions;
      productDetailText.update(this.productid, data.id, data).then(res => {
        this.$snotify.success("Text Form added");
        this.isLoading=false;
        this.resetOptions();
        this.getTextForm();

        this.close();
      });
    },
    resetOptions() {
      this.newOptions = {
        title: "",
        value: [""],
        type: null,
        piceType: null,
        price: null,
        required: 0
      };
    },

    // Delete Faq
    deleteOptions(id) {
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        productDetailText.delete(this.productid, id).then(res => {
                            this.optionslist = res.data;
                            this.$snotify.success("Text Option Delted");
                            this.getTextForm();
                        });
                    }
                }
            }
        )

    }
  },
  mounted() {
    this.getTextForm();
  }
};
</script>
