<template>
  <v-col cols="12" md="12">
    <KTPortlet v-bind:title="'About Product'" v-if="texts ? texts.length>0 :0">
      <template v-slot:toolbar>
        <v-btn
          @click="showDialog"
          class="btn additional-btn"
        >
          <i class="flaticon-add-circular-button"></i> Add Description
        </v-btn>
      </template>
      <template v-slot:body>
        <v-app>
          <v-expansion-panels focusable>
            <v-expansion-panel v-for="(item,i) in texts" :key="i" class="faq-list">
              <v-expansion-panel-header class="font-weight-regular">
                {{ item.title}}
                <span class="text-right">
                  <!-- <i  v-if="item.is_active" class="flaticon2-check-mark kt-font-success"></i>
                  <i v-else class="flaticon2-exclamation kt-font-warning"></i>-->
                  <a href="javascript:void(0)" @click="editTexts(item)" class="ps-1">
                    <img src="@/assets/media/icons/edit-tools.png" class="edit_icon" />
                  </a>
                  <a href="#" @click="deleteDetail(item.id)" class="ps-1">
                    <img src="@/assets/media/icons/send-to-trash.png" class="delete_icon" />
                  </a>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="font-weight-regular pt-5">
                <p v-html="item.description"></p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-app>
      </template>
    </KTPortlet>

    <div class="kt-portlet kt-iconbox" v-else>
      <div class="kt-portlet__body">
        <div class="kt-iconbox__body">

          <div class="kt-iconbox__desc">
            <h3 class="kt-iconbox__title">
              <a class="kt-link" href="#">Additional Description</a>
            </h3>
            <div class="kt-iconbox__content">
              <a href="#" @click="showDialog">Add more details about your product</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog scrollable v-model="descriptionText" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <span v-if="!edit" class="headline">Add Description</span>
          <span v-else class="headline">Update</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="form-block">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field label="Title*" v-model="newtexts.title" required outlined densed></v-text-field>
                  <p
                    class="text-danger font-weight-bold"
                    v-if="$v.newtexts.title.$error"
                  >Title is required</p>
                </v-col>
                <v-col cols="12" md="12">
                  <span>Description</span>
                  <ckeditor v-model="newtexts.description"></ckeditor>
                  <p
                    class="text-danger font-weight-bold"
                    v-if="$v.newtexts.description.$error"
                  >Description is required</p>
                </v-col>
                <v-col cols="2" md="2">
                  <v-text-field
                    label="Position"
                    v-model="newtexts.position"
                    required
                    outlined
                    densed
                  ></v-text-field>
                </v-col>
                <v-col cols="2" md="2">
                  <v-switch label="Active" v-model="newtexts.is_active"></v-switch>
                </v-col>
                <!-- <v-col cols="12" md="12">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    color="red"
                    v-if="index!=0"
                    @click="removeFaq(index)"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    v-if="index===newtexts.length-1 && !edit"
                    color="green"
                    @click="addTexts"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>-->
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Close</v-btn>
          <v-btn
            :loading="isLoading"
            color="white--text v-btn theme--light elevation-2 v-size--large primary"
            class="btn-save-popup"
            text
            @click.prevent="saveOrUpdate"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { required } from "vuelidate/lib/validators";
import NotifyService from "@/common/notify.service";
import ProductService from "@/services/store/ProductService";
import ProductTextDetailService from "@/services/store/ProductTextDetailService";
import CKEditor from "ckeditor4-vue";

const Product = new ProductService();
const productDetailText = new ProductTextDetailService();
export default {
  name: "Product-texts",
  // props: ["texts", "slug"],
  validations: {
    newtexts: {
      title: { required },
      description: { required }
    }
  },
  data: () => ({
    descriptionText: false,
    edit: false,
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    },
    isLoading: false,
    texts: [
      // {
      //   title: "asdasd",
      //   description: "asdas",
      //   sort_position: 1,
      //   is_active: 1
      // }
    ],
    newtexts: {
      product_id: null,
      title: "",
      description: "",
      position: 1,
      is_active: 1
    }
  }),
  components: {
    KTPortlet,
    ckeditor: CKEditor.component
  },
  computed: {
    productid() {
      return this.$route.params.productid;
    }
  },
  methods: {
    showDialog() {
      this.descriptionText = true;
    },
    close() {
      this.descriptionText = false;
      // this.resetDescription();
      // this.$emit("initOrganisation");
    },
    // Add Faq Form
    addTexts() {
      this.newtexts.push({
        title: "",
        description: "",
        sort_position: 1,
        is_active: 1
      });
    },
    editTexts(item) {
      if (item.id != null && item.id != undefined && item.id != "") {
        this.descriptionText = true;
        this.edit = true;
        this.newtexts = item;
      }
    },

    // Remove Faq Form
    removeFaq(index) {
      this.newtexts.splice(index, 1);
    },

    // Save Faq call api
    saveOrUpdate() {
      this.$v.$touch();
      if (!this.$v.newtexts.$error) {
        this.$v.$reset();
        if (!this.edit) {
          this.save();
        } else {
          this.update();
        }
      }
    },
    save() {
      this.isLoading = true;

      productDetailText
        .create(this.productid, this.newtexts)
        .then(res => {
          this.getdetailText();
          this.isLoading = false;
          this.close();
          this.resetDescription();
          this.$snotify.success("Detail added");
        })
        .catch(err => {});
    },
    update() {
      this.isLoading = true;
      productDetailText
        .update(this.productid, this.newtexts.id, this.newtexts)
        .then(res => {
          this.getdetailText();
          this.isLoading = false;
          this.close();
          this.resetDescription();
          this.$snotify.success("Detail updated");
        })
        .catch(err => {});
    },
    resetDescription() {
      this.newtexts = {
        product_id: null,
        title: "",
        description: "",
        position: 1,
        is_active: 1
      };
    },
    getdetailText() {
      productDetailText
        .paginate(this.productid)
        .then(res => {
          this.texts = res.data;
        })
        .catch(err => {});
    },
    // Delete Faq
    deleteDetail(id) {
        this.$confirm(
            {
                message: `Confirm delete  ?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        productDetailText
                            .delete(this.productid, id)
                            .then(res => {
                                this.$snotify.success("Delete Successfully");
                                this.getdetailText();
                            })
                            .catch(err => {});
                    }
                }
            }
        )

    }
  },
  mounted() {
    this.getdetailText();
  }
};
</script>
