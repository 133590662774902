<template>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="product.min_cart_qty"
                label="Min quantity allowed in cart"
                outlined
                densed
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="product.max_cart_qty"
                label="Max quantity allowed in cart"
                outlined
                densed
                required
              ></v-text-field>
            </v-col>


            <v-col cols="6">
              <v-text-field v-model="product.warning_qty" label="Warning Stock Level if below" outlined densed required></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                  v-model="product.inventory_stock"
                  label="Stock Quantity"
                  outlined
                  densed
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-switch v-model="product.manage_stock" label="Manage Stock"></v-switch>
            </v-col>
            <v-col cols="6">
              <v-switch v-model="product.is_out_of_stock" label="Out of stock"></v-switch>

            </v-col>
<!--            <v-col cols="12">-->
<!--              <v-btn-->
<!--                :loading="isLoading"-->
<!--                color="white&#45;&#45;text v-btn theme&#45;&#45;light elevation-2 v-size&#45;&#45;large primary"-->
<!--                class="btn-save-popup pull-right"-->
<!--                text-->
<!--                @click.prevent="updateInventory()"-->
<!--              >Save</v-btn>-->
<!--            </v-col>-->
          </v-row>
        </v-col>
      </v-row>

</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import CKEditor from "ckeditor4-vue";
export default {
  props: ["product","isLoading"],
  name: "store-inventrory",
  data: () => ({
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    }
  }),
  components: {
    KTPortlet,
    ckeditor: CKEditor.component
  },
  methods: {
    updateInventory() {
      this.$emit("update");
    }
  }
};
</script>
