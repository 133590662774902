<template>
  <KTPortlet v-bind:title="'General Information'">
    <template v-slot:toolbar></template>
    <template v-slot:body>
      <v-row>
        <v-col cols="12">
          <v-text-field label="Product Title" v-model="product.title" outlined densed required></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="SKU"
            @input="validateSku"
            v-model="product.sku"
            outlined
            densed
            required
          ></v-text-field>
          <p
            v-if="!skuEror"
            class="text-danger font-weight-bold"
          >Product SKU must be unique</p>
        </v-col>
         <v-col cols="3">
          <v-text-field
            label="BAR CODE"
            @input="validateBarCode"
            v-model="product.bar_code"
            outlined
            densed
            required
          ></v-text-field>
          <p
            v-if="!barCodeError"
            class="text-danger font-weight-bold"
          >Barcode already been taken</p>
        </v-col>
        <v-col cols="3">
          <v-text-field label="Weight" v-model="product.weight" outlined densed required></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            :items="weight_units"
            label="Weight Units"
            v-model="product.weight_unit"
            outlined
            densed
            required
          ></v-select>
        </v-col>
        <v-col cols="12">
          <h6>Description</h6>
          <ckeditor :config="editorConfig" v-model="product.description"></ckeditor>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Video URL" v-model="product.video_url" outlined densed required></v-text-field>
        </v-col>
        <v-col cols="12">
          <h5>Product Ribbon</h5>
        </v-col>

        <v-col cols="12">
          <v-text-field v-model="product.ribbon_text" label="Ribbon Title" outlined densed required></v-text-field>
        </v-col>
        <v-col cols="2">
          <span>Ribbon Color</span>
          <verte
                  menuPosition="bottom"
                  model="hex"
                  v-model="product.ribbon_color"
          >
          </verte>
        </v-col>
<!--        <v-col cols="12">-->
<!--          <v-btn-->
<!--            :loading="isLoading"-->
<!--            color="white&#45;&#45;text v-btn theme&#45;&#45;light elevation-2 v-size&#45;&#45;large primary"-->
<!--            class="btn-save-popup pull-right"-->
<!--            text-->
<!--            @click.prevent="updateGeneral()"-->
<!--          >update</v-btn>-->
<!--        </v-col>-->
      </v-row>
    </template>
  </KTPortlet>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import CKEditor from "ckeditor4-vue";
import ProductService from "@/services/store/ProductService";
const Product = new ProductService();
export default {
  name: "store-info",
  props: ["product","isLoading"],
  data: () => ({
    skuEror: true,
    barCodeError: true,
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    },
    weight_units: ["mg", "g", "kg", "ltr", "ton"]
  }),
  components: {
    KTPortlet,
    ckeditor: CKEditor.component
  },
  methods: {
    updateGeneral() {
      this.$emit("update");
    },
    validateSku() {
      Product.verifyProductSku(this.product.sku)
        .then(response => {
          this.skuEror = response.data;
        })
        .catch(error => {
          return false;
        });
    },
    validateBarCode() {
      Product.verifyProductSku(this.product.bar_code)
        .then(response => {
          this.barCodeError = response.data;
        })
        .catch(error => {
          return false;
        });
    }
  }
};
</script>

<style scoped>
  .theme--light.v-tabs-items {
    background-color: none !important;
  }
</style>
