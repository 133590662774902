import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class EcommerceProductVariantPricingService {


      index(productId, variantId){
            let url = `${API_URL}user/ecommerce/product/${productId}/variant/${variantId}/price`;
            return apiService.get(url);
      }

      create(productId, variantId, data){
            let url = `${API_URL}user/ecommerce/product/${productId}/variant/${variantId}/price`;
            return apiService.post(url, data);
      }

      show(productId, variantId, priceId){
            let url = `${API_URL}user/ecommerce/product/${productId}/variant/${variantId}/price/${priceId}`;
            return apiService.get(url);
      }

      update(productId, variantId, priceId, data){
            let url = `${API_URL}user/ecommerce/product/${productId}/variant/${variantId}/price/${priceId}`;
            return apiService.put(url, data);
      }

      show(productId, variantId, priceId){
            let url = `${API_URL}user/ecommerce/product/${productId}/variant/${variantId}/price/${priceId}`;
            return apiService.delete(url);
      }
}
