<template>
  <div>
    <h3>Manage Product</h3>

    <v-btn
      outlined
      style="background: transparent"
      type="submit"
      :loading="isLoading"
      class="btn btn-primary mr-2 mb-2"
      text
      @click.prevent="updateProduct()"
    >
      <i class="far fa-save"></i> Save
    </v-btn>

    <v-btn
      text
      :to="{ name: 'product-list' }"
      outlined
      class="btn btn-lg btn-secondary-main mb-2"
      style="background: transparent"
    >
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>

    <v-app>
      <v-row>
        <div class="kt-portlet kt-portlet--tabs mt-5">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-toolbar">
              <ul
                ref="builder-tab"
                class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-left nav-tabs-line-primary"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    v-on:click="setActiveTab"
                    data-tab="0"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="true"
                  >General</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="1"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >Additional Descriptions & Modifiers</a>
                </li>
                <li class="nav-item"  v-if="product.type == 'digital'" >
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="2"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >Digital Products</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="3"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >Gallery</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="4"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >Pricing</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="5"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >Variants</a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="6"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >Attributes</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="7"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >Inventory & Stock</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="8"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >Packaging & Delivery</a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="9"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >SEO</a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-on:click="setActiveTab"
                    data-tab="10"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="false"
                  >Setting</a>
                </li>
              </ul>
            </div>
          </div>

          <!--begin::Form-->
          <form class="kt-form kt-form--label-right">
            <div class="kt-portlet__body">
              <b-tabs class="kt-hide-tabs" v-model="tabIndex">
                <b-tab active>
                  <div class="kt-section kt-margin-t-30">
                    <div class="kt-section__body">
                      <general
                        v-bind:product="product"
                        :isLoading="isLoading"
                        @update="updateProduct"
                      ></general>
                      <!-- <pre>{{product}}</pre> -->
                      <catlogs
                        v-bind:product="product"
                        :isLoading="isLoading"
                        @update="updateProduct"
                      ></catlogs>

                      <brand
                        v-bind:product="product"
                        :isLoading="isLoading"
                        @update="updateProduct"
                      ></brand>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                  <div class="kt-section kt-margin-t-30">
                    <div class="kt-section__body">
                      <description></description>
                      <texts></texts>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <div class="kt-section kt-margin-t-30">
                    <div class="kt-section__body">
                      <digital-product v-if="product.type == 'digital'"></digital-product>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <div class="kt-section kt-margin-t-30">
                    <div class="kt-section__body">
                      <media :isLoading="isLoading" @call_gallery="callGallery"></media>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                  <div class="kt-section kt-margin-t-30">
                    <div class="kt-section__body">
                      <pricing
                        v-if="product.id"
                        :product="product"
                        :isLoading="isLoading"
                        @update="updateProduct"
                      ></pricing>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                  <div class="kt-section kt-margin-t-30">
                    <div class="kt-section__body">
                      <variation
                        v-bind:product="product"
                        :isLoading="isLoading"
                        @update="updateProduct"
                        ref="variation"
                      ></variation>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                  <div class="kt-section kt-margin-t-30">
                    <div class="kt-section__body">
                      <attribute></attribute>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                  <div class="kt-section kt-margin-t-30">
                    <div class="kt-section__body">
                      <inventory
                        v-bind:product="product"
                        :isLoading="isLoading"
                        @update="updateProduct"
                      ></inventory>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                  <div class="kt-section kt-margin-t-30">
                    <div class="kt-section__body">
                      <delivery
                        v-bind:product="product"
                        :isLoading="isLoading"
                        @update="updateProduct"
                      ></delivery>

                      <warrenty
                        v-bind:product="product"
                        :isLoading="isLoading"
                        @update="updateProduct"
                      ></warrenty>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                label="SEO Title"
                                v-model="product.seo_title"
                                outlined
                                densed
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-textarea
                                label="SEO Description"
                                v-model="product.seo_description"
                                outlined
                                densed
                                required
                              ></v-textarea>
                            </v-col>
                            <v-col>
                              <v-combobox
                                v-model="product.seo_keyword"
                                :search-input.sync="search"
                                hide-selected
                                label="Add some Keywords"
                                multiple
                                densed
                                outlined
                                persistent-hint
                                small-chips
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        No results matching "
                                        <strong>{{ search }}</strong>". Press
                                        <kbd>enter</kbd> to create a
                                        new one
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-combobox>
                            </v-col>
                          </v-row>
                </b-tab>

                <b-tab>
                          <setting :setting="product" @update="updateProduct"></setting>

                </b-tab>
              </b-tabs>
            </div>

            <div class="kt-portlet__foot">
              <div class="kt-form__actions">
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-9">
                    <v-btn
                      type="submit"
                      :loading="isLoading"
                      x-large
                      class="btn btn-primary mr-2 pull-right"
                      text
                      @click.prevent="updateProduct()"
                    >
                      <i class="far fa-save"></i>
                      Save
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!--end::Form-->
        </div>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import CKEditor from "ckeditor4-vue";
import General from "./Profile/General";
import Warrenty from "./Profile/ServiceWarrenty";
import Delivery from "./Profile/DeliverySize";
import Description from "./Profile/Description";
import Attribute from "./Profile/Attribute";
import Catlogs from "./Profile/Catlogs";
import Inventory from "./Profile/Inventory";
import Media from "./Profile/Media";
import Variation from "./Profile/Variation";
import Pricing from "./Profile/Pricing";
import Texts from "./Profile/DescriptionTexts";
import ProductService from "@/services/store/ProductService";
import Treeselect from "@riophae/vue-treeselect";
import setting from "./Profile/Setting";
import Brand from "./Profile/Brand";
import DigitalProduct from "./Profile/DigitalProduct";

const Product = new ProductService();
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "product-detail",
  data: () => ({
    selected: [],
    tabIndex: 0,
    seoKeywords: [],
    isLoading: false,
    product: {
      user_id: "",
      catalog_id: "",
      title: "",
      description: "",
      sku: "",
      type: "physical",
      price: 0,
      special_price: "",
      special_price_start_date: null,
      special_price_end_date: null,
      inventory_stock: 10,
      min_cart_qty: 2,
      max_cart_qty: 5,
      manage_stock: 1,
      ribbon_text: null,
      ribbon_color: null,
      position: null,
      condition: "new",
      views: null,
      has_variant: 1,
      no_of_variant: null,
      variant_title_1: null,
      variant_title_2: null,
      variant_title_3: null,
      is_active: 1,
      weight: null,
      weight_unit: null
    },
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    },
    tab: null,
    tab1: null,
    dates: ["2019-09-10", "2019-09-20"],
    productAttributes: [],
    optionslist: [
      {
        value: null,
        type: null,
        piceType: null,
        price: null,
        required: 0
      }
    ],

    options: [
      {
        id: "a",
        label: "a",
        children: [
          {
            id: "aa",
            label: "aa"
          },
          {
            id: "ab",
            label: "ab"
          }
        ]
      },
      {
        id: "b",
        label: "b"
      },
      {
        id: "c",
        label: "c"
      }
    ],
    items: ["Dropdown", "Radio", "Checkbox", "Text Field"],
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  }),
  components: {
    KTPortlet,
    ckeditor: CKEditor.component,
    General,
    Description,
    Attribute,
    Catlogs,
    Delivery,
    Warrenty,
    Variation,
    Inventory,
    Media,
    Texts,
    Pricing,
    treeselect: Treeselect,
    setting,
    Brand,
    DigitalProduct
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    productid() {
      return this.$route.params.productid;
    }
  },
  methods: {
    getProduct() {
      Product.get(this.productid).then(res => {
        this.product = res.data.product;
        if (!this.product.sku) {
          this.getUniqueSku();
        }
        this.product.catalog_ids = this.product.catalog_ids.map(value => {
          return Number(value);
        });
        this.product.ecommerce_brand_ids = this.product.ecommerce_brand_ids.map(
          value => {
            return Number(value);
          }
        );
      });
    },
    getUniqueSku() {
      Product.getUniqueSku(this.productid)
        .then(res => {
          this.product.sku = res.data.sku;

          // console.log(this.product.sku)
        })
        .catch(error => {
          //console.log(error);
        });
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },
    updateProduct() {
      this.isLoading = true;
      Product.update(this.productid, this.product).then(res => {
        this.isLoading = false;
        this.$snotify.success("Product updated Successfully");
        this.$refs.variation.getVariant();
        this.getProduct();
      });
    },
    callGallery() {
      this.$refs.variation.getMedia();
    }
  },
  mounted() {
    this.getProduct();
  }
};
</script>

<style scoped>
.v-application--wrap {
  min-height: auto !important;
}

.kt-font-big {
  font-size: 35px;
}

.theme--light.v-application {
  background: none !important;
}

.v-item-group,
theme--light,
v-tabs-items {
  background-color: none !important;
}

/*.v-item-group.theme--light.v-tabs-items {*/
/*    background-color: none !important;*/
/*}*/

.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.noarrow .kt-notification .kt-notification__item:after {
  display: none;
}

.fixed-button-save {
  position: fixed;
  bottom: 50px;
  z-index: 99;
  right: 80px;
}
</style>
