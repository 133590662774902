<template>
  <div>
    <v-col cols="12" sm="12" md="12" lg="12" v-if="variantCurrencies.length>0">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Currency</th>
            <th class="text-left">Price</th>
            <th class="text-left">Special Price</th>
            <th class="text-left">From</th>
            <th class="text-left">To</th>
            <th class="text-left">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in variantCurrencies" :key="item.symbol">
            <td>{{ item.code }}</td>
            <td>{{ item.symbol }} {{ item.price }}</td>
            <td> {{ item.special_price ? item.symbol + ' ' + item.special_price : '-' }}</td>
            <td>{{ (item.special_price_end_date) ? item.special_price_end_date : '-' }}</td>
            <td>{{ (item.special_price_start_date) ? item.special_price_start_date : '-' }}</td>
            <td>
              <v-btn @click="editCurrencies(item)" depressed small><i class="fas fa-edit"></i></v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-row v-if="!hideForm">
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-select
            v-model="currency.currency_id"
            label="Currency"
            :items="currencies"
            item-text="symbol"
            item-value="id"
            outlined
            densed
        ></v-select>
        <span class="text-danger" v-if="$v.currency.currency_id.$error">Currency symbol is requried</span>
      </v-col>

      <v-col cols="6" sm="12" md="6" lg="6">
        <v-text-field label="price *" type="number" v-model.number="currency.price" dense outlined></v-text-field>
        <span class="text-danger" v-if="$v.currency.price.$error">Price is requried</span>
      </v-col>
      <v-col cols="4" sm="12" md="4" lg="4">
        <v-text-field
            label="Special Price *"
            v-model.number="currency.special_price"
            densed
            outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-menu
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="currency.special_price_start_date"
                label="Applies  from"
                outlined
                densed
                prepend-inner-icon="event"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="currency.special_price_start_date" @input="menu3 = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu
            v-model="menu4"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="currency.special_price_end_date"
                label="Applies  to"
                outlined
                densed
                prepend-inner-icon="event"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="currency.special_price_end_date" @input="menu4 = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-switch dense v-model="currency.is_active" :label="'Status'"></v-switch>
      </v-col>
      <v-col cols="6" sm="12" md="6" lg="6" class="text-right">
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text :color="'red'" @click="closeDialog">Cancel</v-btn>
        <v-btn
            color="green darken-1"
            text
            @click="createOrUpdate"
            v-text="edit ? 'Edit' : 'Add'"
        >Add
        </v-btn>
      </v-col>
      <!-- {{currency}} -->
    </v-row>
  </div>
</template>
<script>
import EcommerceCurrencyPricingService from "@/services/store/EcommerceCurrencyPricingService";
import EcommerceProductVariantPricingService from "@/services/store/EcommerceProductVariantPricingService";
import {required} from "vuelidate/lib/validators";

const pricingService = new EcommerceProductVariantPricingService();
const currencyService = new EcommerceCurrencyPricingService();

export default {
  name: "variant-pricing",
  props: ["product_id", "variant_id"],
  validations: {
    currency: {currency_id: {required}, price: {required}}
  },
  data() {
    return {
      hideForm: false,
      edit: false,
      currencies: [],
      variantCurrencies: [],
      index: 0,
      currency: {
        currency_id: null,
        price: null,
        special_price: null,
        special_price_start_date: null,
        special_price_end_date: null,
        is_active: true
      },
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false
    };
  },
  mounted() {
    this.getAvailableCurrencies();
  },
  updated() {
    if (this.product_id && this.variant_id) {
      this.index += 1;
      if (this.index == 1) {
        this.prices()
      }
    }
  },
  methods: {
    getAvailableCurrencies() {
      currencyService
          .getAavailableCurrencies()
          .then(response => {
            this.currencies = response.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },
    prices() {
      pricingService
          .index(this.product_id, this.variant_id)
          .then(response => {
            this.variantCurrencies = response.data;
            if (this.currencies.length == this.variantCurrencies.length)
              this.hideForm = true;
          })
          .catch(error => {
            // console.log(error);
          });
    },
    createOrUpdate() {
      this.$v.currency.$touch();
      if (this.$v.currency.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 2000);
      } else {
        if (!this.edit) this.create();
        else this.update();
      }
    },
    create: function () {
      pricingService
          .create(this.product_id, this.variant_id, this.currency)
          .then(response => {
            this.$snotify.success("Currency created successfully");
            this.resetForm();
          })
          .catch(error => {
            this.errors = error.response.data.errors;
          });
    },
    update: function () {
      pricingService
          .update(this.product_id, this.variant_id, this.currency.id, this.currency)
          .then(response => {
            this.$snotify.success("currency updated successfully");
            this.resetForm();
          })
          .catch(error => {
            this.errors = error.response.data.errors;
          });
    },
    resetForm() {
      this.edit = false;
      this.currency = {
        id: null,
        symbol: null,
        country_id: null,
        available_gateways: [],
        is_active: true
      };
      this.$v.$reset();
      this.prices();
      this.getAvailableCurrencies();
    },
    closeDialog() {
      this.variantCurrencies = [];
      this.$emit("close_dialog");
    },
    editCurrencies(item) {
      this.currency = item;
      this.edit = true;
      this.hideForm = false;
    }
  }
};
</script>
