<template>
  <v-col cols="12" md="12">
    <template>
      <v-row>
        <v-col cols="12">
          <v-btn @click="addCustomAttribute"
                 color="v-btn success"
                 class="btn-success pull-right"
          >
            <i class="flaticon-add mr-2"></i>Add product attribute
          </v-btn>
        </v-col>

      </v-row>

      <v-app>
          <v-col cols="12">
            <v-simple-table dense light fixed-header>
              <template>
                <tbody v-if="productAttributeList.length>0">
                  <v-row v-for="(item,i) in productAttributeList" :key="i">
                    <v-col cols="4">
                      <span class="subtitle-1">{{ (item.attribute_title)?item.attribute_title:item.title }}</span>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-model="item.value"
                        v-if="item.type=='text'"
                        :label="item.title"
                        required
                        densed
                        outlined
                      ></v-text-field>
                      <v-select
                        v-if="item.type=='select'"
                        :items="item.options"
                        :label="item.title"
                        v-model="item.value"
                        required
                        densed
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="1">
                      <a href="#" @click="deleteFromProductAttribute(i)">
                        <i class="fas fa-trash"></i>
                      </a>
                    </v-col>
                  </v-row>
                </tbody>
              </template>
            </v-simple-table>
            <div v-for="(item,i) in attributeList" :key="i">
              <v-row>
                <v-col cols="4">
                  <span class="subtitle-1">{{ item.title }}</span>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="item.value"
                    v-if="item.type=='text'"
                    :label="item.title"
                    required
                    dense
                    outlined
                  ></v-text-field>
                  <v-select
                    v-if="item.type=='select'"
                    :items="item.options"
                    :label="item.title"
                    v-model="item.value"
                    required
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="white--text v-btn theme--light elevation-2 v-size--large primary"
              class="btn-save-popup pull-right"
              text
              @click.prevent="saveAttributes()"
            >Save</v-btn>
          </v-col>
          <v-col cols="6"></v-col>
          <v-col cols="6"></v-col>
        <v-dialog scrollable v-model="custom_attribute" max-width="600">
          <v-card>
            <v-card-title class="headline">Add Attribute</v-card-title>

            <v-card-text>
              <v-row>
                <v-col sm="12" md="12" lg="12">
                  <v-text-field outlined dense v-model="attribute_custom.title" label="Attribute Name"></v-text-field>
                  <span class="text-danger" v-if="$v.attribute_custom.title.$error">Attribute Name is required</span>
                </v-col>
                <v-col sm="12" md="12" lg="12">
                  <v-text-field outlined dense v-model="attribute_custom.value" label="Attribute Value"></v-text-field>
                  <span class="text-danger" v-if="$v.attribute_custom.value.$error">Attribute value is required</span>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="black" text @click="custom_attribute = false">Close</v-btn>

              <v-btn class="btn btn-primary" text @click="saveCustomAttribute">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-app>
    </template>
  </v-col>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import CKEditor from "ckeditor4-vue";
import ProductService from "@/services/store/ProductService";
import ProductAttributeService from "@/services/store/ProductAttributeService";

const Product = new ProductService();
const attrubuteService = new ProductAttributeService();
export default {
  name: "store-attributes",
  validations: {
    attribute_custom: {
      title: { required },
      value: { required }
    }
  },
  data: () => ({
    custom_attribute: false,
    attribute_custom: {
      title: null,
      value: null,
      product_id: null,
      attribute_id:null
    },
    attributeList: [],
    productAttributeList: [],
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    }
  }),
  components: {
    KTPortlet,
    ckeditor: CKEditor.component
  },
  computed: {
    productid() {
      return this.$route.params.productid;
    }
  },
  methods: {
    getProductAttributes() {
      Product.getAttribute(this.productid).then(res => {
        this.attributeList = res.data.attribute_list;
        this.productAttributeList = res.data.product_attribute_list;
      });
    },
    saveAttributes() {
      let data = [];
      this.productAttributeList.forEach(element => {
        if (
          element.value != null &&
          element.value != "" &&
          element.value != undefined
        ) {
          let temp = {
            id: element.id,
            attribute_id: element.attribute_id,
            value: element.value,
            is_active: element.is_active
          };
          data.push(temp);
        }
      });
      this.attributeList.forEach(element => {
        if (
          element.value != null &&
          element.value != "" &&
          element.value != undefined
        ) {
          let temp = {
            attribute_id: element.id,
            value: element.value,
            is_active: element.is_active
          };
          data.push(temp);
        }
      });
      if (data.length <= 0) {
        this.$snotify.error("No attribute values have been set.");
      } else {
        this.syncAttribute(data);
      }
    },
    syncAttribute(data) {
      attrubuteService
        .syncAttribute(this.productid, data)
        .then(res => {
          this.getProductAttributes();
        })
        .catch(error => {});
    },
    deleteFromProductAttribute(i) {
        this.$confirm(
            {
                message: `Are you sure you want to delete this attribute?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        this.productAttributeList.splice(i, 1);
                    }
                }
            }
        )

    },
    addCustomAttribute() {
      this.custom_attribute = true;
    },
    saveCustomAttribute() {
      this.$v.attribute_custom.$touch();
      if (this.$v.attribute_custom.$error) {
        setTimeout(() => {
          this.$v.attribute_custom.$reset();
        }, 3000);
      } else {
        attrubuteService
          .create(this.productid, this.attribute_custom)
          .then(response => {
            this.$snotify.success("Custom attribute added successfully");
            this.resetAttribute();
          })
          .catch(error => {
            // console.log(error);
          });
      }
    },
    resetAttribute() {
      this.attribute_custom = {
        title: null,
        value: null,
        product_id: this.productid
      };
      this.$v.attribute_custom.$reset();
      this.custom_attribute = false;
      this.getProductAttributes();
    }
  },
  mounted() {
    this.getProductAttributes();
    this.attribute.product_id = this.productid;
  }
};
</script>
