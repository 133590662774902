<template>
    <v-row>
        <v-col cols="12">
            <v-row>
                <v-col cols="10">
                    <h6>Enable Request Quote</h6>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-switch v-model="setting.request_quote"></v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10">
                    <h6>Status</h6>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-switch v-model="setting.is_active"></v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10">
                    <h6>Enable Review</h6>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-switch v-model="setting.review_enabled"></v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10">
                    <h6>For Sale</h6>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-switch v-model="setting.is_sale"></v-switch>
                </v-col>


            </v-row>
            <v-row>
                <v-col cols="10">
                    <h6>Featured</h6>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-switch v-model="setting.is_featured"></v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10">
                    <h6>Popular</h6>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-switch v-model="setting.is_popular"></v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <h6>Product Type</h6>
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-select dense outlined v-model="setting.type" :items="product_types" item-value="value"
                              item-text="title"></v-select>
                </v-col>
            </v-row>

        </v-col>
        <!--    <v-col cols="12">-->
        <!--      <v-btn-->
        <!--        color="white&#45;&#45;text v-btn theme&#45;&#45;light elevation-2 v-size&#45;&#45;large primary"-->
        <!--        class="btn-save-popup pull-right"-->
        <!--        text-->
        <!--        @click.prevent="updateProduct()"-->
        <!--      >Save</v-btn>-->
        <!--    </v-col>-->
    </v-row>
</template>
<script>
    export default {
        name: "setting",
        props: ["setting"],
        data() {
            return {
                product_setting: {},
                product_types: [
                    {"value": 'digital', title: "Digital"},
                    {"value": 'physical', title: "Physical"},
                    {"value": 'service', title: "Service"},
                ]
            };
        },
        methods: {
            updateProduct() {
                this.$emit("update");
            }
        }
    };
</script>
