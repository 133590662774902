import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class DigitalProductService {


    paginate(productID,index = null) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/digital';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    get(id) {
        let url = API_URL + 'user/ecommerce/ecommerce/product/' + productID + '/digital';
        return apiService.get(url);
    }
    getAttribute(productID) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/digital';
        return apiService.get(url);
    }
    create(productID,data) {

        let url = API_URL + 'user/ecommerce/product/' + productID + '/digital';;
        return apiService.post(url, data);
    }

    update(productID,id, data) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/digital/'+id+'/update';
        return apiService.post(url, data);
    }

    delete(productID,id) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/digital/'+id;
        return apiService.delete(url);
    }
    show(id) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/digital';
        return apiService.get(url);
    }


}
