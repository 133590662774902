import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class EcommerceCurrencyPricingService {


    paginate(productId, type = null, flag = null, index = null, ) {
        let url = API_URL + 'user/ecommerce/product/' + productId + '/pricing';
        if (index != null)
            url = url + '?page=' + index
        if (type != null)
            url = url + '?type=' + type
        if (flag != null)
            url = url + '?flag=' + flag
        return apiService.get(url);
    }
    getAavailableCurrencies(){
        let url = API_URL + 'user/ecommerce/currency'
        return apiService.get(url);
    }

    create(productId, data) {
        let url = API_URL + 'user/ecommerce/product/' + productId + '/pricing'
        return apiService.post(url, data);
    }


    update(productId, id, data) {
        let url = API_URL + 'user/ecommerce/product/' + productId + '/pricing/' + id
        return apiService.put(url, data);
    }

    delete(productId, id) {
        let url = API_URL + 'user/ecommerce/product/' + productId + '/pricing/' + id
        return apiService.delete(url);
    }
    show(productId, id) {
        let url = API_URL + 'user/ecommerce/product/' + productId + '/pricing/' + id
        return apiService.get(url);
    }




}
