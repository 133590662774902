<template>
<!--  <KTPortlet v-bind:title="'Pricing'">-->
<!--    <template v-slot:toolbar>-->

<!--    </template>-->
    <div>
      <v-row>
        <v-col cols="12">
          <v-btn @click="openDialog(item)" depressed small color="success" class="float-right mt-2 mb-5"> <i class="fa fa-plus"></i> Add Price</v-btn>
          <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Default</th>
                  <th class="text-center">Currency</th>
                  <th class="text-center">Price</th>
                  <th class="text-center">Special Price</th>
                  <th class="text-center">From</th>
                  <th class="text-center">To</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody v-if="product_pricings.length>0">
                <tr v-for="item in product_pricings" :key="item.symbol">
                  <td class="text-center">
                    <v-badge bordered :color="item.is_default ?'success':'error'" overlap></v-badge>
                  </td>
                  <td class="text-center">{{item.currency}}</td>
                  <td class="text-center">{{ item.symbol }} {{ item.price }}</td>
                  <td
                    class="text-center"
                  >{{ (item.special_price)? item.symbol + item.special_price:'-' }}</td>
                   <td
                    class="text-center"
                  >{{ (item.special_price_start_date)?item.special_price_start_date:"-" }}</td>
                  <td
                    class="text-center"
                  >{{ (item.special_price_end_date)?item.special_price_end_date:"-" }}</td>
                  <td
                    class="text-center"
                  ><span><i class="fas" :class="item.is_active?'text-success fa-check':'text-danger fa-ban'"></i></span>
                  </td>
                  <td class="text-center">
                   <a href="#"  @click="editCurrencies(item)" depressed small class="m-2 cursor-pointer"> <i class="fas fa-edit"></i></a>

                    <a href="#" @click="_delete(item)" depressed small class="m-2 cursor-pointer"> <i class="fas fa-trash"></i></a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                  <tr>
                    <td class="text-center mt-2"  colspan="10"><h4>No data found</h4></td>
                  </tr>
              </tbody>
            </table>
        </v-col>


        <!--            <v-col cols="12">-->
        <!--              <v-btn-->
        <!--                :loading="isLoading"-->
        <!--                color="white&#45;&#45;text v-btn theme&#45;&#45;light elevation-2 v-size&#45;&#45;large primary"-->
        <!--                class="btn-save-popup pull-right"-->
        <!--                text-->
        <!--                @click.prevent="updatePricing()"-->
        <!--              >Save</v-btn>-->
        <!--            </v-col>-->
      </v-row>
      <v-dialog scrollable v-model="newDialog" persistent max-width="900">
        <v-card>
          <v-card-title class="headline">Add Price & Currency</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6" sm="12" md="6" lg="6">
                <v-select
                  v-model="currency.country_id"
                  label="Currency"
                  :items="countries"
                  :item-text="item => `${item.title} - ${item.currency} (${item.symbol})`"
                  item-value="id"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="6" sm="12" md="6" lg="6">
                <v-text-field
                  label="Price *"
                  type="number"
                  v-model.number="currency.price"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="6" sm="12" md="6" lg="6">
                <v-text-field
                  label="Special Price *"
                  v-model.number="currency.special_price"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="currency.special_price_start_date"
                      label="Apply Date from"
                      outlined
                      dense
                      prepend-inner-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="currency.special_price_start_date" @input="menu3 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="menu4"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="currency.special_price_end_date"
                      label="Apply Date to"
                      outlined
                      dense
                      prepend-inner-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="currency.special_price_end_date" @input="menu4 = false"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6" sm="12" md="6" lg="6">
                <v-switch dense v-model="currency.is_active" :label="'Status'"></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <pre>{{package}}</pre> -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDialog">Cancel</v-btn>
            <v-btn
              class="btn btn-primary"
              text
              @click="createOrUpdate"
              v-text="edit ? 'Edit' : 'Add'"
            >Add</v-btn>
          </v-card-actions>
        </v-card>
            <!-- <pre>{{currency}}</pre> -->
      </v-dialog>
    </div>
<!--  </KTPortlet>-->
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import CKEditor from "ckeditor4-vue";
import EcommerceCurrencyPricingService from "@/services/store/EcommerceCurrencyPricingService";
import Country from "@/services/address/CountryService";

const country = new Country();
const productPricing = new EcommerceCurrencyPricingService();
export default {
  props: ["product", "isLoading"],
  name: "store-pricing",
  data: () => ({
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    },
    newDialog: false,
    search: "",
    edit: false,
    page: null,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    menu3: false,
    menu4: false,

    // define the default value
    value: null,
    currency: {
      currency_id: null,
      price: null,
      special_price: null,
      special_price_start_date: null,
      special_price_end_date: null,
      is_active: true
    },
    countries: [],
    product_pricings: []
    // define options
  }),
  components: {
    KTPortlet,
    ckeditor: CKEditor.component
  },computed:{
    curren_user(){
      return  this.$store.getters.currentUser;
    }
  },
  methods: {
    updatePricing() {
      this.$emit("update");
    },
    getCurrencies() {
      country
        .all()
        .then(response => {
          this.countries = response.data;
        })
        .catch(error => {
          // console.log(error);
        });
    },
    openDialog(id) {
      this.newDialog = true;
    },
    closeDialog() {
      this.newDialog = false;
    },
    editCurrencies(item) {
      this.currency = item;
      this.currency.country_id = Number(this.currency.country_id);
      this.edit = true;
      this.openDialog();
    },
    createOrUpdate() {
      if (!this.edit) this.create();
      else this.update();
    },
    productPricings() {
      productPricing
        .paginate(this.product.id)
        .then(response => {
          this.product_pricings = response.data;
        })
        .catch(error => {
          // console.log(error);
        });
    },

    create: function() {
      // console.log(this.package);
      productPricing
        .create(this.product.id, this.currency)
        .then(response => {
          this.$snotify.success("Currency created successfully");
          this.resetForm();
        })
        .catch(error => {
          this.$snotify.error("You are not allowed to add multiple price for same country");
          this.errors = error.response.data.errors;
        });
    },
    update: function() {
      productPricing
        .update(this.product.id, this.currency.id, this.currency)
        .then(response => {
          this.$snotify.success("currency updated successfully");
          this.resetForm();
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    },
    _delete: function(item) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            productPricing.delete(this.product.id,item.id)
              .then(response => {
                this.$snotify.success("Product Pricing Deleted");
                this.resetForm();
              })
              .catch(error => {
                // console.log(error);
              });
          }
        }
      });
    },
    resetForm() {
      this.edit = false;
      this.closeDialog();
      this.currency = {
        id: null,
        symbol: null,
        country_id: this.curren_user.country_id,
        available_gateways: [],
        is_active: true
      };
      this.productPricings();
      // this.getCurrencies();
    },
    formatPaymentGateways(value) {
      return value.join(",");
    }
  },
  mounted() {
    this.getCurrencies();
    this.productPricings();
    this.currency.country_id =this.curren_user.country_id
  }
};
</script>
