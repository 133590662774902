<template>
  <v-col cols="12" md="12">
    <KTPortlet v-bind:title="'Digital Product'" v-if="digitalProductList.length>0">
      <template v-slot:toolbar>
        <v-btn
          @click="showDialog"
          class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
          style="background:transparent;"
        >
          <i class="flaticon-add-circular-button"></i>
        </v-btn>
      </template>
      <template v-slot:body>
        <v-app>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Type</th>
                      <th class="text-center">Max Download Allowed</th>
                      <th class="text-center">Position</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) of digitalProductList" :key="i">
                      <td class="text-center">{{d.type.toUpperCase()}}</td>
                      <td class="text-center">{{d.max_download_allowed}}</td>
                      <td class="text-center">{{(d.position)?d.position:'-'}}</td>
                      <td class="text-center">
                        <i class="fa fa-edit" @click="editDigitalProduct(d)"></i>
                        <i class="fa fa-trash ml-3" @click="deleteOptions(d.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-app>
      </template>
    </KTPortlet>

    <div class="kt-portlet kt-iconbox" v-else>
      <div class="kt-portlet__body">
        <div class="kt-iconbox__body">

          <div class="kt-iconbox__desc">
            <h3 class="kt-iconbox__title">
              <a class="kt-link" href="#" @click.prevent="showDialog"> Digital Files</a>
            </h3>
            <div class="kt-iconbox__content">
              <a href="#" @click="showDialog">Add your digital product files</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog scrollable v-model="digitalProduct" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <span v-if="!edit" class="headline">Add new</span>
          <span v-else class="headline">Update file</span>
        </v-card-title>
        <v-card-text>
          <v-row v-for="(digital_product, i) of digital_products" :key="i">
            <hr />
            <v-col cols="1" class="text-center">
              <v-btn fab small dark style="font-size:18px;font-weight:700;">{{i+1}}</v-btn>
            </v-col>
            <v-col cols="3">
              <v-select
                :items="options"
                item-text="title"
                item-value="value"
                outlined
                dense
                label="Select Type"
                v-model="digital_product.type"
              ></v-select>
              <span class="text-danger" v-if="$v.digital_products.$each[i].type.$error">
                <strong>Type is required</strong>
              </span>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="digital_product.max_download_allowed"
                type="number"
                min="0"
                outlined
                dense
                label="Maximum Download Allowed"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="digital_product.position"
                type="number"
                outlined
                dense
                label="position"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-file-input
                outlined
                dense
                v-model="digital_product.path"
                v-if="digital_product.type == 'file'"
                accept="image/*"
                label="Upload File"
              ></v-file-input>
              <v-text-field v-else v-model="digital_product.path" outlined dense label="Path"></v-text-field>
              <span class="text-danger" v-if="$v.digital_products.$each[i].path.$error">
                <strong>Path or file is required</strong>
              </span>
            </v-col>
            <v-col cols="5">
              <v-file-input
                outlined
                dense
                v-model="digital_product.sample_path"
                v-if="digital_product.type == 'file'"
                accept="image/*"
                label="Upload File"
              ></v-file-input>
              <v-text-field
                v-else
                v-model="digital_product.sample_path"
                outlined
                dense
                label="Sample Path"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                v-if="!edit"
                @click="remove(digital_product.id, i)"
                fab
                dark
                small
                class="pull-right"
                style="font-size:15px;font-weight:700;"
              >
                <i class="fa fa-trash"></i>
              </v-btn>
            </v-col>
          </v-row>
          <!-- <pre>{{digital_products}}</pre> -->
          <b-btn v-if="!edit" @click="addNew">Add New</b-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Close</v-btn>
          <v-btn
            :loading="isLoading"
            color="white--text v-btn theme--light elevation-2 v-size--large primary"
            class="btn-save-popup"
            text
            @click.prevent="saveOrUpdate"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { required } from "vuelidate/lib/validators";
import NotifyService from "@/common/notify.service";
import ProductService from "@/services/store/ProductService";
import DigitalProductService from "@/services/store/DigitalProductService";

const Product = new ProductService();
const digitalProductService = new DigitalProductService();
export default {
  name: "digitla-product",
  // props: ["texts", "slug"],
  validations: {
    digital_products: {
      required,
      $each: {
        type: { required },
        path: { required }
      }
    }
  },
  data: () => ({
    isLoading: false,
    digitalProduct: false,
    digitalProductList: [],
    digital_products: [],
    edit: false,
    digital_products: [
      {
        type: "file",
        max_download_allowed: "10",
        postition: "1",
        is_active: 1,
        path: null,
        sample_path: null
      }
    ],
    options: [
      { value: "file", title: "File" },
      // { value: "url", title: "Url" }
    ]
  }),
  components: {
    KTPortlet
  },
  computed: {
    productid() {
      return this.$route.params.productid;
    }
  },
  methods: {
    showDialog() {
      this.digitalProduct = true;
    },
    close() {
      this.digitalProduct = false;
      this.reset();
    },
    // Add Faq Form

    editTexts(item) {
      if (item.id != null && item.id != undefined && item.id != "") {
        this.digital_product = item;
      }
    },
    getDigitalProduct() {
      digitalProductService.paginate(this.productid).then(res => {
        this.digitalProductList = res.data;
      });
    },
    saveOrUpdate() {
      this.isLoading = true;
      this.$v.digital_products.$touch();
      if (this.$v.digital_products.$error) {
        setTimeout(() => {
          this.isLoading = false;
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.edit) {
          this.save();
        } else {
          this.update();
        }
      }
    },
    buildFormData(data) {
      let fd = new FormData();
      data.forEach(element => {
        for (let key in element) {
          // console.log(key);
          if (
            element[key] === "path" ||
            element[key] != undefined ||
            element[key] != ""
          ) {
            fd.append(key + "[]", element[key]);
          } else if (
            element[key] === "sample_path" ||
            element[key] != undefined ||
            element[key] != ""
          ) {
            fd.append(key + "[]", element[key]);
          } else {
            fd.append(key + "[]", element[key] ? element[key] : "");
          }
        }
      });
      return fd;
    },
    save() {
      let data = this.buildFormData(this.digital_products);
      digitalProductService
        .create(this.productid, data)
        .then(res => {
          this.$snotify.success("Digital Product added");
          this.isLoading = false;
          this.reset();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      let data = this.buildFormData(this.digital_products);
      digitalProductService
        .update(this.productid, this.digital_products[0].id, data)
        .then(res => {
          this.$snotify.success("Digital Product updated");
          this.reset();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    reset() {
      this.digital_products = [];
      this.$v.$reset();
      this.digital_products.push({
        type: "url",
        path: null,
        sample_path: null,
        max_download_allowed: null,
        postition: null,
        is_active: 1
      });
      this.isLoading = false;
      this.edit = false;
      this.close();
      this.getDigitalProduct();
    },
    addNew() {
      this.digital_products.push({
        type: "url",
        max_download_allowed: "10",
        postition: "1",
        is_active: 1,
        path: null,
        sample_path: null
      });
    },
    remove(id, i) {
      if (id == null || id == undefined || id == "")
        this.digital_products.splice(i, 1);
      else this.deleteOptions(id);
    },

    deleteOptions(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            digitalProductService.delete(this.productid, id).then(res => {
              this.optionslist = res.data;
              this.$snotify.success("Text Option Delted");
              this.getDigitalProduct();
            });
          }
        }
      });
    },
    editDigitalProduct(item) {
      this.digitalProduct = true;
      this.digital_products = [];
      this.edit = true;
      this.digital_products.push(item);
    }
  },
  mounted() {
    this.getDigitalProduct();
  }
};
</script>
