import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class CountryService {

      #api;
      constructor(){
            this.#api = "user/country";
      }
      all(){
            let url  = `${this.#api}/all`;
            // console.log(url)
            return apiService.get(url);
      }
      getAllExcluding(){
            let url  = `${this.#api}/all-excluding`;
            // console.log(url)
            return apiService.get(url);
      }

      getCurrencies(){
            let url  = `user/currency`;
            return apiService.get(url);
      }

      getAvailablePaymentMethodsByUser(){
            let url  = `user/available-currencies/by-user`;
            return apiService.get(url);
      }
}
