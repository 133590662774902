import Vue from "vue";
import { API_URL } from "@/common/config";
import Swal from "sweetalert2";

/**
 * Service to call HTTP request via Axios
 */
const NotifyService = {

    sucess(message) {
        alert(message)
        this.$snotify.success('Example body content');
    },
    confirm(message) {
        return Swal.fire({
            title: (!message) ?  "Are you sure?" : message,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        });
    },
    delete(message) {
        Swal.fire("Deleted!", message, "success");
    }, error() {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
        })
    }


};

export default NotifyService;
