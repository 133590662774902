import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class ProductService {


    paginate(productFilter = null, index = null) {
        let url = API_URL + 'user/ecommerce/product';
        var queryString = null;
        if (productFilter != null && productFilter != undefined && productFilter != "") {
            queryString = Object.keys(productFilter).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(productFilter[key])
        }).join('&');
            if (queryString.length != 0)
                url = url + '?' + queryString;
        }
        // console.log(queryString)
        if (index != null) {
            if (queryString != null && queryString != '')
                url = url + '&page=' + index
            else
                url = url + '?page=' + index
        }


        return apiService.get(url);
    }
    getCounts(id) {
        let url = API_URL + 'user/ecommerce/product-counts';
        return apiService.get(url);
    }
    verifyVariantSku(productId, sku) {
        let url = API_URL + 'user/ecommerce/product/' + productId + '/variant/validate/sku?sku=' + sku;
        return apiService.get(url);
    }
    verifyProductSku(sku) {
        let url = API_URL + 'user/ecommerce/product/validate/sku?sku=' + sku;
        return apiService.get(url);
    }
    verifyProductSku(barcode) {
        let url = API_URL + 'user/ecommerce/product/validate/bar-code?bar_code=' + barcode;
        return apiService.get(url);
    }

    get(id) {
        let url = API_URL + 'user/ecommerce/product/' + id;
        return apiService.get(url);
    }

    getAttribute(productID) {
        let url = API_URL + 'user/ecommerce/product/' + productID + '/attribute-list';
        return apiService.get(url);
    }
    create(data) {
        let url = API_URL + 'user/ecommerce/product';
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = API_URL + 'user/ecommerce/product/' + id
        return apiService.put(url, data);
    }

    delete(id) {
        let url = API_URL + 'user/ecommerce/product/' + id
        return apiService.delete(url);
    }
    show(id) {
        let url = API_URL + 'user/ecommerce/product/' + id
        return apiService.get(url);
    }

    cloneProduct(productId) {
        let url = API_URL + `user/ecommerce/product/${productId}/clone`
        return apiService.get(url);
    }

    recoverProdcut(productId) {
        let url = API_URL + `user/ecommerce/product/${productId}/recover`
        return apiService.get(url);
    }

    getUniqueSku(productId) {
        let url = API_URL + `user/ecommerce/product/${productId}/generate-sku`
        return apiService.get(url);
    }

    productReport(filter) {
        let url = API_URL + `user/ecommerce/product/report/generate`
        if (filter != null && filter != undefined && filter != "") {
            var queryString = Object.keys(filter).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])
            }).join('&');
            if (queryString.length != 0)
                url = url + '?' + queryString;
        }
        return apiService.get(url);
    }

    performAction(data) {
        let url = API_URL + `user/ecommerce/product/perform/action`
        return apiService.post(url, data);
    }


}
