<template>
        <KTPortlet v-bind:title="'Brand'">
            <template v-slot:toolbar></template>
            <template v-slot:body>
                <div class="kt-notification kt-notification--fit">
                    <v-select
                        v-model="product.ecommerce_brand_ids"
                        :items="brands"
                        label="Brands"
                        item-text="title"
                        item-value="id"
                        multiple
                        outlined
                        dense
                        attach
                        chips
                    ></v-select>
                </div>
<!--                <v-col cols="12">-->
<!--                    <v-btn-->
<!--                        :loading="isLoading"-->
<!--                        color="white&#45;&#45;text v-btn theme&#45;&#45;light elevation-2 v-size&#45;&#45;large primary"-->
<!--                        class="btn-save-popup pull-right"-->
<!--                        text-->
<!--                        @click.prevent="updateProduct()"-->
<!--                    >Save-->
<!--                    </v-btn>-->
<!--                </v-col>-->
            </template>
        </KTPortlet>
        <!-- <pre>{{selected}}</pre> -->
</template>
<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import EcommerceBrand from "@/services/store/EcommerceBrandService";

    const brandService = new EcommerceBrand();
    export default {
        name: "brand",
        props: ["product", "isLoading"],
        data: () => ({
            selected: [],
            brands: []
        }),
        components: {
            KTPortlet
        }, mounted() {
            this.getBrands();
        },
        methods: {
            getBrands() {
                brandService
                    .byUser()
                    .then(response => {
                        this.brands = response.data.data;
                    })
                    .catch(error => {
                    });
            },

        }
    };
</script>
