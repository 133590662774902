<template>
  <v-col cols="12" md="12">
<!--    <KTPortlet v-bind:title="'Gallery'">-->
<!--      <template v-slot:toolbar></template>-->
      <template>
        <div
          class="uploader uploader-v2"
          @dragenter="OnDragEnter"
          @dragleave="OnDragLeave"
          @dragover.prevent
          @drop="onDrop"
          :class="{ dragging: isDragging }"
        >
          <div class="upload-control" v-show="images.length">
            <v-btn :loading="isLoading" class="btn-save-popup pull-right" @click="upload">Upload</v-btn>
          </div>

          <div v-show="!images.length">
            <i class="fa fa-cloud-upload"></i>
            <p>Drag your files here</p>
            <div>or</div>
            <div class="file-input">
              <label for="file">Select a file</label>
              <input type="file" id="file" @change="onInputChange" multiple />
            </div>
          </div>

          <div class="images-preview" v-show="images.length">
            <div class="img-wrapper" v-for="(image, index) in images" :key="index">
              <img :src="image" :alt="`Image Uplaoder ${index}`" />
              <div class="details">
                <span class="name" v-text="files[index].name"></span>
                <span class="size" v-text="getFileSize(files[index].size)"></span>
              </div>
            </div>
            <div class="add-btn-wrapper">
              <div class="icon-wrap">
                <label for="file">
                  <i class="fa fa-plus"></i>
                </label>
              </div>
            </div>
          </div>
        </div>
        <v-container fluid>
            <vue-nestable v-model="medias" @change="sortMedia">
                <template slot-scope="{ item,index }">
              <vue-nestable-handle :item="item">
                  <i class="fas far fa-hand-paper" />
              </vue-nestable-handle>
                    <i  @click.prevent="deleteImg(item.id)"  class="pull-right fas fa-trash" />

                        <progressive-img
                            :src="item.image_path.thumb"
                            style="width:100%"
                            aspect-ratio="1"
                            max-height="150"
                            max-width="250"
                            class="grey lighten-2"
                        >
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>

                        </progressive-img>

                </template>
            </vue-nestable>
        </v-container>
      </template>
<!--    </KTPortlet>-->
  </v-col>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import CKEditor from "ckeditor4-vue";
import ProductService from "@/services/store/ProductService";
import ProductMediaService from "@/services/store/ProductMediaService";
import { VueNestable, VueNestableHandle } from "vue-nestable";

const Product = new ProductService();
const productMedia = new ProductMediaService();
export default {
  name: "store-media",
  props: ["isLoading"],
  data: () => ({
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    },
    isDragging: false,
    dragCount: 0,
    files: [],
    images: [],
    error: false,
    absolute: true,
    overlay: false,
    medias: [],
    totalSize: null,
    page: null,
    pageCount: null,
    snackbar: false,
    text: "My timeout is set to 2000.",
    timeout: 2000
  }),
  components: {
    KTPortlet,
      VueNestable,
      VueNestableHandle

  },
  methods: {
    OnDragEnter(e) {
      e.preventDefault();

      this.dragCount++;
      this.isDragging = true;

      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;

      if (this.dragCount <= 0) this.isDragging = false;
    },
    onInputChange(e) {
      const files = e.target.files;

      Array.from(files).forEach(file => this.addImage(file));
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      this.isDragging = false;

      const files = e.dataTransfer.files;

      Array.from(files).forEach(file => this.addImage(file));
    },
    addImage(file) {
      const type = file.name.split(".")[1];
      if (file.type.match("image.*")) {
        this.addFiles(file);
      } else if (type == "pdf") {
        this.addFiles(file);
      } else if (type == "pptx") {
        this.addFiles(file);
      } else if (type == "doc") {
        this.addFiles(file);
      } else if (type == "docx") {
        this.addFiles(file);
      } else if (type == "csv") {
        this.addFiles(file);
      } else if (type == "xlsx") {
        this.addFiles(file);
      } else if (type == "mp3") {
        this.addFiles(file);
      } else if (type == "mp4") {
        this.addFiles(file);
      } else {
        // alert(`${file.name} is not an image`);
        this.error = true;
        // file.type.split('/')[1]
        return;
      }
    },

    onCopy: function(e) {
      this.text = "You just copied URL ";
      this.snackbar = true;
    },
    onError: function(e) {
      this.text = "Failed to copy URL";
      this.snackbar = true;
    },
    addFiles(file) {
      this.error = false;
      this.files.push(file);

      const img = new Image(),
        reader = new FileReader();

      reader.onload = e => this.images.push(e.target.result);

      reader.readAsDataURL(file);
    },
    deleteImg(id) {
        this.$confirm(
            {
                message: `Confirm delete file ?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        productMedia
                            .delete(this.productid, id)
                            .then(res => {
                                this.$snotify.success("Deleted");
                                this.getMedia();
                                this.$emit("call_gallery");
                            })
                            .catch(err => {
                                this.isLoading = false;
                            });
                    }
                }
            }
        )

    },
    getFileSize(size) {
      const fSExt = ["Bytes", "KB", "MB", "GB"];
      let i = 0;

      while (size > 900) {
        size /= 1024;
        i++;
      }

      return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
    },
    upload() {
      const formData = new FormData();
      this.isLoading = true;
      this.files.forEach(file => {
        formData.append("path[]", file);
        formData.append("size[]", file.size);
        // formData.append("name[]", file.name);
        // formData.append("type[]", file.name.split(".")[1]);
      });

      // alert("the total size uploaded is " + this.getFileSize(this.totalSize));
      productMedia
        .create(this.productid, formData)
        .then(res => {
          this.$snotify.success("Uploaded");
          this.getMedia();
          this.images = [];
          this.files = [];
          this.$emit("call_gallery");
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
        });
    },
    getMedia(index = null) {
      productMedia.paginate(this.productid, index).then(response => {
        this.medias = response.data;
        // this.page = response.data.meta.current_page;
        // this.pageCount = response.data.meta.last_page;
      });
    },
    sortMedia() {
      let ids = [];
      this.medias.forEach(element => {
        ids.push(element.id);
      });
      // console.log(ids);
      productMedia
        .sortMedia(this.productid, { ids: ids })
        .then(response => {
          this.$snotify.success("Image sorted successfully");
          this.getMedia();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          // this.$emit("call_gallery");
        });
    }
  },
  computed: {
    productid() {
      return this.$route.params.productid;
    }
  },
  mounted() {
    this.getMedia();
  }
};
</script>

<style lang="scss" scoped>


.uploader {
  width: 100%;
  background: #fff;
  color: #777;
  padding: 10px 15px 102px - 30px;
  text-align: center;
  border-radius: 10px;
  border: 1px dashed #696969 !important;
  font-size: 20px;
  position: relative;

  &.dragging {
    background: #fff;
    color: #2196f3;
    border: 1px dashed #696969 !important;

    .file-input label {
      background: #2196f3;
      color: #fff;
    }
  }

  i {
    font-size: 85px;
  }

  .file-input {
    width: 200px;
    margin: auto;
    height: 68px;
    position: relative;

    label,
    input {
      background: #2196f3;
      color: #fff;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 10px;
      border-radius: 4px;
      margin-top: 7px;
      cursor: pointer;
    }

    input {
      opacity: 0;
      z-index: -2;
    }
  }

  .images-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px;
    .img-wrapper {
      display: flex;
      flex-direction: column;

      // justify-content: space-between;

      img {
        max-height: 150px;
          object-fit: contain;
      }
    }

    & > * {
      /*height: 140px;*/
      width: (100 / 5) * 1%;
      padding: 10px 15px;
      margin: 0 15px 30px;
      background: #fff;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
    }

    .add-btn-wrapper {
      width: 43%;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
    }

    .details {
      font-size: 12px;
      background: #fff;
      color: #000;
      display: flex;
      word-break: break-all;
      flex-direction: column;
      align-items: self-start;
      padding: 3px 6px;

      .name {
        overflow: hidden;
        height: 18px;
      }
    }
  }

  .upload-control {
    position: absolute;
    width: 100%;
    background: #f5f5f5;
    bottom: 0;
    left: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 10px;
    padding-bottom: 4px;
    text-align: right;

    button,
    label {
      background: #2196f3;
      border: 2px solid #03a9f4;
      border-radius: 3px;
      color: #fff;
      font-size: 15px;
      cursor: pointer;
    }

    label {
      padding: 2px 5px;
      margin-right: 10px;
    }
  }
}

.uploader-v2 {
  .images-preview {
    .img-wrapper {
      width: 43%;
      margin: 0 auto 12px;
    }
  }
}

</style>

<style lang="scss" scope>
    /*
    * Style for nestable
    */

    .nestable {
        position: relative;
    }
    .nestable-rtl {
        direction: rtl;
    }
    .nestable .nestable-list {
        margin: 0;
        padding: 0 0 0 40px;
        list-style-type: none;
    }
    .nestable-rtl .nestable-list {
        padding: 0 40px 0 0;
    }
    .nestable > .nestable-list {
        padding: 0;
    }
    .nestable-item,
    .nestable-item-copy {
        margin: 10px 0 0;
    }
    .nestable-item:first-child,
    .nestable-item-copy:first-child {
        margin-top: 0;
    }
    .nestable-item .nestable-list,
    .nestable-item-copy .nestable-list {
        margin-top: 10px;
    }
    .nestable-item {
        position: relative;
    }
    .nestable-item.is-dragging .nestable-list {
        pointer-events: none;
    }
    .nestable-item.is-dragging * {
        opacity: 0;
        filter: alpha(opacity=0);
    }
    .nestable-item.is-dragging:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(106, 127, 233, 0.274);
        border: 1px dashed rgb(73, 100, 241);
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }
    .nestable-drag-layer {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        pointer-events: none;
    }
    .nestable-rtl .nestable-drag-layer {
        left: auto;
        right: 0;
    }
    .nestable-drag-layer > .nestable-list {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        background-color: rgba(106, 127, 233, 0.274);
    }
    .nestable-rtl .nestable-drag-layer > .nestable-list {
        padding: 0;
    }
    .nestable [draggable="true"] {
        cursor: move;
    }
    .nestable-handle {
        display: inline;
    }
    .nestable-item .btn-group {
        float: right;
    }
    .nestable-item .nestable-item-content,
    .kt-portlet__body ol.nestable-list .nestable-item-content {
        color: black;
        font-weight: bold;
        padding: 10px 10px;
        border-radius: 5px;
        width: 15%;
        float: left;
        margin: 5px;
        background: #eceff0;
    }
    .nestable-handle {
        margin-right: 10px;
        cursor: grabbing !important;
    }

    .nestable-handle span {
        font-weight: bold !important;
    }
    .uploader-v2 .images-preview .img-wrapper {
        width: 15% !important;
        margin: 0 auto 12px;
    }
    .uploader .images-preview .add-btn-wrapper{
        width: 15% !important;
    }
</style>
