<template>
    <v-app>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="6">
                        <h5>Enable variations for this product?
                        </h5>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        <v-switch
                                v-model="product.has_variant" class="pull-right"
                        ></v-switch>
                    </v-col>
                </v-row>

                <v-row v-if="product.has_variant">
                    <v-col cols="12">
                        <h5>Select number of variations</h5>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="product.no_of_variant"
                                :items="variantNo"
                                item-text="text"
                                item-value="value"
                                outlined
                                densed
                                label="Select Maximum number of variants"
                        ></v-select>
                        <!-- <v-btn
                      color="white--text v-btn theme--light elevation-2 v-size--large primary"
                      class="btn-save-popup pull-right"
                      text
                      @click.prevent="updateGeneral()"
                        >Save</v-btn>-->
                    </v-col>

                    <v-col cols="12">

                        <v-row v-if="product.no_of_variant>='1'">
                            <v-col>
                                <v-text-field
                                        v-model="product.variant_title_1"
                                        label="Variation Name"
                                        outlined
                                        densed
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-combobox
                                        v-model="product.variant_option_1"
                                        hide-selected
                                        label="Add option"
                                        multiple
                                        densed
                                        outlined
                                        persistent-hint
                                        small-chips
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Press
                                                    <kbd>enter</kbd> to add
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row v-if="product.no_of_variant>='2' ">
                            <v-col>
                                <v-text-field
                                        v-model="product.variant_title_2"
                                        label="Variation Name "
                                        outlined
                                        densed
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-combobox
                                        v-model="product.variant_option_2"
                                        hide-selected
                                        label="Add option"
                                        multiple
                                        densed
                                        outlined
                                        persistent-hint
                                        small-chips
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Press
                                                    <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row v-if="product.no_of_variant>='3'">
                            <v-col>
                                <v-text-field
                                        v-model="product.variant_title_3"
                                        label="Variation Name"
                                        outlined
                                        densed
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-combobox
                                        v-model="product.variant_option_3"
                                        hide-selected
                                        label="Add option"
                                        multiple
                                        dense
                                        outlined
                                        persistent-hint
                                        small-chips
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Press
                                                    <kbd>enter</kbd> to add
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row v-if="product.no_of_variant>='1'">
                            <v-col>
                <span>
                  Possible variations for this product is
                  <strong>
                    {{ combination }}
                    <v-checkbox
                            :disabled="combination>50"
                            v-model="product.autogenerate"
                            label="Generate possible variant combination for pricing ?"
                    ></v-checkbox>
                  </strong>
                </span>
                            </v-col>
                            <v-col>
                                <v-btn
                                        :loading="isLoading"
                                        color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                        class="btn-save-popup pull-right text-white"
                                        text
                                        @click.prevent="updateGeneral()"
                                >Save & Generate Variant
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-divider></v-divider>
                </v-row>
            </v-col>

            <v-dialog scrollable v-model="galaryDialog" max-width="800">
                <v-card>
                    <v-card-title class="headline">Select variant image</v-card-title>

                    <v-card-text>
                        <v-radio-group v-model="selectedVariant.image_id" row v-if="medias.length">
                            <template v-slot:label>
                                <!-- <div>
                                  Product Gallery
                                  <strong>Select One</strong>
                                </div>-->
                            </template>
                            <v-radio :value="media.id" v-for="media in medias" :key="media.id">
                                <template v-slot:label>
                                    <v-img
                                            :src="media.image_path.thumb"
                                            aspect-ratio="1"
                                            class="grey lighten-2"
                                            height="150"
                                            width="150"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate
                                                                     color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </template>
                            </v-radio>

                        </v-radio-group>
                        <span v-else>No images available. Upload images and link your images</span>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="black" text @click="galaryDialog = false">close</v-btn>

                        <v-btn class="btn btn-primary" text @click="galaryDialog = false">Set Image</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog scrollable v-model="descriptionDialog" max-width="800">
                <v-card>
                    <v-card-title class="headline">Product Variant Description</v-card-title>

                    <v-card-text>
                        <v-textarea label="Variant Description" outlined rows="5" v-model="selectedVariant.description">

                        </v-textarea>


                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="green darken-1" text @click="descriptionDialog = false">close</v-btn>

                        <v-btn color="green darken-1" text @click="descriptionDialog = false">Select</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog scrollable v-model="priceDialog" max-width="800">
                <v-card>
                    <v-tabs
                            v-model="tab"

                    >
                        <!--                <v-tabs-slider></v-tabs-slider>-->

                        <v-tab href="#tab-1">
                            Pricing
                            <!-- <v-icon>mdi-phone</v-icon> -->
                        </v-tab>

                        <v-tab href="#tab-2">
                            Additional Pricing
                            <!-- <v-icon>mdi-heart</v-icon> -->
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-1">
                            <v-card-title class="headline">Variant Price</v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                                label="Title"
                                                v-model="selectedVariant.title"
                                                outlined
                                                densed
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                                prefix="Rs."
                                                label="Price"
                                                v-model="selectedVariant.price"
                                                outlined
                                                densed
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                                label="Special Price"
                                                prefix="Rs."
                                                v-model="selectedVariant.special_price"
                                                outlined
                                                densed
                                                required
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-menu
                                                v-model="spf"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="selectedVariant.special_price_start_date"
                                                        label="Applies from "
                                                        outlined
                                                        densed
                                                        prepend-inner-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                    v-model="selectedVariant.special_price_start_date"
                                                    @input="spf = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-menu
                                                v-model="spt"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="selectedVariant.special_price_end_date"
                                                        label="Applies  to"
                                                        outlined
                                                        densed
                                                        prepend-inner-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                    v-model="selectedVariant.special_price_end_date"
                                                    @input="spt = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn color="green darken-1" text @click="closePriceDialog">close</v-btn>

                                <v-btn
                                        color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                        class="btn-save-popup pull-right text-white"
                                        text
                                        @click="closePriceDialog"
                                >Save
                                </v-btn>
                            </v-card-actions>
                        </v-tab-item>
                    </v-tabs-items>
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-2">
                            <v-card-title class="headline">Variant Price & Currency</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <variant-pricing :product_id="product.id"
                                                         :variant_id="selectedVariant.id"
                                                         @close_dialog="closePriceDialog"></variant-pricing>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row v-if="product.has_variant">
            <v-row>
                <v-col cols="12">
                    <v-btn
                            color="v-btn success"
                            class="btn-success pull-right"
                            @click.prevent="addSKu()"
                    >+ Add Variant
                    </v-btn>
                </v-col>
                <v-col cols="12" v-if="productVariants.length">
                    <v-skeleton-loader v-if="loading" :loading="loading" height="94"
                                       type="table"></v-skeleton-loader>

                    <table class="table" v-else dense>
                        <thead>
                        <tr>
                            <th class="text-center"></th>
                            <th
                                    class="text-center"
                                    v-if="product.variant_title_1"
                            >{{ product.variant_title_1 }}
                            </th>
                            <th
                                    class="text-center"
                                    v-if="product.variant_title_2"
                            >{{ product.variant_title_2 }}
                            </th>
                            <th
                                    class="text-center"
                                    v-if="product.variant_title_3"
                            >{{ product.variant_title_3 }}
                            </th>
                            <th class="text-center">Price</th>
                            <th class="text-center">SKU</th>
                            <th class="text-center"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,i) in productVariants" :key="i">
                            <td>
                                <v-switch class="mt-3" v-model="item.is_active" label></v-switch>
                            </td>
                            <td v-if="product.variant_title_1">
                                <v-select
                                        :readonly="(item.variant_option_1)?true:false"
                                        :items="product.variant_option_1"
                                        outlined
                                        densed
                                        label="Select Variant"
                                        v-model="item.variant_option_1"
                                ></v-select>
                            </td>
                            <td v-if="product.variant_title_2">
                                <v-select
                                        :readonly="(item.variant_option_2)?true:false"
                                        :items="product.variant_option_2"
                                        outlined
                                        densed
                                        v-model="item.variant_option_2"
                                        label="Select Variant"
                                ></v-select>
                            </td>
                            <td v-if="product.variant_title_3">
                                <v-select
                                        class="mt-3"
                                        :readonly="(item.variant_option_3)?true:false"
                                        :items="product.variant_option_3"
                                        outlined
                                        densed
                                        v-model="item.variant_option_3"
                                        label="Select Variant"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        label="Price"
                                        v-model="item.price"
                                        outlined
                                        densed
                                        required
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        label="SKU"
                                        v-model="item.sku"
                                        @input="validateSku(item.sku)"
                                        outlined
                                        densed
                                        required
                                ></v-text-field>
                                <p
                                        v-if="!skuEror"
                                        class="text-danger font-weight-bold"
                                >Product SKU must be unique</p>
                            </td>
                            <td>
                                <v-btn color="black" class="pull-right" text @click="deleteVariant(item)">
                                    <i class="fas fa-trash"></i>
                                </v-btn>
                                <v-btn color="black" class="pull-right" text @click.stop="openDialog(item)">
                                    <i class="fas fa-file-image"></i>
                                </v-btn>
                                <v-btn color="black" class="pull-right" text @click.stop="openDescriptionDialog(item)">
                                    <i class="fas fa-align-justify"></i>
                                </v-btn>
                                <v-btn color="black" class="pull-right" text @click="openPriceDialog(item)">
                                    <i class="fas fa-dollar-sign"></i>
                                </v-btn>

                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td rowspan="10"></td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td>
                                <v-btn
                                        style="margin-left:auto"
                                        :loading="isLoading"
                                        color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                        class="btn-save-popup pull-right text-white"
                                        text
                                        @click.prevent="updateVariant()"
                                >Save Variants
                                </v-btn>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </v-col>
            </v-row>
        </v-row>
    </v-app>
</template>
<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import ProductService from "@/services/store/ProductService";
    import ProductMediaService from "@/services/store/ProductMediaService";
    import ProductVariantService from "@/services/store/ProductVariantService";
    import VariantPricing from "./VariantPricing";

    const productVariant = new ProductVariantService();
    const Product = new ProductService();
    const productMedia = new ProductMediaService();

    export default {
        name: "variation",
        props: ["product", "isLoading"],
        components: {
            KTPortlet,
            "variant-pricing": VariantPricing
        },
        data: () => ({
            tab: null,
            skuEror: true,
            autogenerate: false,
            medias: null,
            row: false,
            galaryDialog: false,
            descriptionDialog: false,
            priceDialog: false,
            loading: true,
            productVariants: [],
            spf: false,
            spt: false,
            selectedVariant: {
                image_id: null
            },
            variantNo: [
                {
                    text: "One",
                    value: 1
                },
                {
                    text: "Two",
                    value: 2
                },
                {
                    text: "Three",
                    value: 3
                }
            ],
            // variantOption1: [],
            // variantOption2: [],
            // variantOption3: [],

            dates: ["2019-09-10", "2019-09-20"]
        }),
        methods: {
            addOptions() {
                this.optionslist.push({
                    value: null,
                    type: null,
                    piceType: null,
                    price: null,
                    required: null
                });
            },
            removeOption(index) {
                this.optionslist.splice(index, 1);
            },
            updateGeneral() {
                this.$emit("update");
                this.getVariant();
            },
            getMedia(index = null) {
                productMedia
                    .paginate(this.productid, index)
                    .then(response => {
                        this.medias = response.data;
                        // this.page = response.data.meta.current_page;
                        // this.pageCount = response.data.meta.last_page;
                    })
                    .catch(error => {
                    });
            },
            updateVariant() {
                productVariant
                    .updateProdcutVariants(this.productid, this.productVariants)
                    .then(response => {
                        this.$snotify.success("Vairant updated");
                        this.getVariant();
                    })
                    .catch(error => {
                    });
            }, deleteVariant(item) {
                this.$confirm(
                    {
                        message: `Confirm delete ?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                productVariant
                                    .delete(this.productid, item.id)
                                    .then(response => {
                                        this.$snotify.success("Vairant deleted");
                                        this.getVariant();
                                    })
                                    .catch(error => {
                                    });
                            }
                        }
                    }
                )

            },
            openDialog(item) {
                this.selectedVariant = item;
                this.galaryDialog = true;
            }, openDescriptionDialog(item) {
                this.selectedVariant = item;
                this.descriptionDialog = true;
            },
            openPriceDialog(item) {
                this.selectedVariant = item;
                this.priceDialog = true;
            },
            closePriceDialog() {
                this.priceDialog = false;
            },
            addSKu() {
                this.productVariants.unshift({
                    image_id: 0,
                    is_active: 0,
                    position: null,
                    price: "",
                    product_id: null,
                    quantity: 0,
                    sku: "",
                    special_price: "",
                    special_price_end_date: "",
                    special_price_start_date: "",
                    title: "",
                    variant_option_1: "",
                    variant_option_2: "",
                    variant_option_3: null
                });
            },
            getVariant() {
                productVariant
                    .paginate(this.productid)
                    .then(response => {
                        if (response.data.length > 0) {
                            this.productVariants = response.data;
                        }
                        setTimeout(() => {
                            this.loading = false;
                        }, 3000);
                    })
                    .catch(error => {
                    });
            },
            validateSku(sku) {
                Product.verifyVariantSku(this.product.id, sku)
                    .then(response => {
                        this.skuEror = response.data;
                    })
                    .catch(error => {
                        return false;
                    });
            }
        },
        computed: {
            productid() {
                return this.$route.params.productid;
            },
            combination() {
                if (
                    this.product.variant_option_1 &&
                    !this.product.variant_option_2 &&
                    !this.product.variant_option_3
                ) {
                    return this.product.variant_option_1.length;
                } else if (
                    this.product.variant_option_1 &&
                    this.product.variant_option_2 &&
                    !this.product.variant_option_3
                ) {
                    return (
                        this.product.variant_option_1.length *
                        this.product.variant_option_2.length
                    );
                } else if (
                    this.product.variant_option_1 &&
                    this.product.variant_option_2 &&
                    this.product.variant_option_3
                ) {
                    return (
                        this.product.variant_option_1.length *
                        this.product.variant_option_2.length *
                        this.product.variant_option_3.length
                    );
                } else return 0;
            }
        },
        mounted() {
            this.getMedia();
            this.getVariant();
        }
    };
</script>

<style scoped>
    .theme--light.v-tabs-items {
        background-color: none !important;
    }
</style>
